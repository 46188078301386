import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import queryString from "query-string";

import "../../css/default.css";

const isMobile = window.innerWidth <= 480;

const SessionIpa = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [name, setName] = useState("홈브루");
	const [text, setText] = useState("님에게 잘 어울리는 가전은,");
	const [nameStyle, setNameStyle] = useState({
		fontSize: "17px",
		marginTop: "4px",
	});

	useEffect(() => {
		const qs = queryString.parse(location.search);

		if (qs.n) {
			setName(qs.n);

			if (window.innerWidth > 1024) {
				if (qs.n.length === 6) {
					setNameStyle({
						fontSize: "17px",
						marginTop: "4px",
					});
				} else if (qs.n.length === 5) {
					setNameStyle({
						fontSize: "19px",
						marginTop: "2px",
					});
				} else if (qs.n.length === 4) {
					setNameStyle({
						fontSize: "21px",
						marginTop: "1px",
					});
				} else {
					setNameStyle({
						fontSize: "24.69px",
						marginTop: "0px",
					});
				}
			} else if (window.innerWidth <= 1024 && window.innerWidth > 480) {
				if (qs.n.length === 6) {
					setNameStyle({
						fontSize: "calc(17/390*100vw)",
						marginTop: "calc(4/390*100vw)",
					});
				} else if (qs.n.length === 5) {
					setNameStyle({
						fontSize: "calc(19/390*100vw)",
						marginTop: "calc(2/390*100vw)",
					});
				} else if (qs.n.length === 4) {
					setNameStyle({
						fontSize: "calc(21/390*100vw)",
						marginTop: "calc(1/390*100vw)",
					});
				} else {
					setNameStyle({
						fontSize: "calc(24.69/390*100vw)",
					});
				}
			} else {
				if (qs.n.length === 6) {
					setNameStyle({
						fontSize: "calc(17/670*var(--ih, 100vh))",
						marginTop: "calc(4/670*var(--ih, 100vh))",
					});
				} else if (qs.n.length === 5) {
					setNameStyle({
						fontSize: "calc(19/670*var(--ih, 100vh))",
						marginTop: "calc(2/670*var(--ih, 100vh))",
					});
				} else if (qs.n.length === 4) {
					setNameStyle({
						fontSize: "calc(21/670*var(--ih, 100vh))",
						marginTop: "calc(1/670*var(--ih, 100vh))",
					});
				} else {
					setNameStyle({
						fontSize: "calc(24.69/670*var(--ih, 100vh))",
					});
				}
			}
		} else {
			setText("님에게 잘 어울리는 가전은,");
		}

		document.body.style.overflow = "auto";
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="container">
				<div className="result sei fadein">
					<div className="center_content">
						<div className="result_text_box">
							<div className="left_content result_line">
								<div className="result_name_box">
									<div className="center_content">
										<p
											className="result_text_name"
											style={nameStyle}
										>
											{name}
										</p>
									</div>
									<img
										src="img/result/line.png"
										alt="line"
										className="result_img_line"
									></img>
								</div>
								<p className="result_text_line">{text}</p>
							</div>
						</div>
					</div>
					<div className="center_content">
						<div
							className="home_btn center_content"
							style={{ opacity: "0" }}
						>
							<a href="https://www.mybl.kr">홈버튼</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SessionIpa;
